(function() {
  var handleScroll, initialize, isSticky, isVisible, slideeAttention;

  isSticky = false;

  isVisible = function(el) {
    var $t, $w, _bottom, _top, compareBottom, compareTop, viewBottom, viewTop;
    $t = $(el);
    $w = $(window);
    viewTop = $w.scrollTop();
    viewBottom = viewTop + $w.height();
    _top = $t.offset().top;
    _bottom = _top + $t.height();
    compareTop = _bottom;
    compareBottom = _top;
    return (compareBottom <= viewBottom) && (compareTop >= viewTop);
  };

  slideeAttention = function() {
    return $('.slidee-prev, .slidee-next').each((function(_this) {
      return function(i, el) {
        el = $(el);
        if (isVisible(el, true)) {
          el.addClass('flash');
          setTimeout(function() {
            return el.removeClass('flash');
          }, 5000);
          return $(window).off('scroll', slideeAttention);
        }
      };
    })(this));
  };

  handleScroll = function() {
    var scrollTop, shouldSticky;
    scrollTop = $(window).scrollTop();
    shouldSticky = scrollTop >= 96;
    if (shouldSticky !== isSticky) {
      if (shouldSticky) {
        $(document.body).addClass('sticky-header');
      } else {
        $(document.body).removeClass('sticky-header');
      }
      isSticky = shouldSticky;
    }
    return $('.parallax-wrapper').each(function(i, parallax) {
      var $pallax, isFixed, parallaxTop, shouldFixed;
      $pallax = $(parallax);
      parallaxTop = $pallax.offset().top;
      shouldFixed = scrollTop >= parallaxTop;
      isFixed = $pallax.data('background-attachment-fixed');
      if (shouldFixed !== isFixed) {
        if (shouldFixed) {
          $pallax.css('background-attachment', 'fixed');
          return $pallax.data('background-attachment-fixed', true);
        } else {
          $pallax.css('background-attachment', 'scroll');
          return $pallax.data('background-attachment-fixed', false);
        }
      }
    });
  };

  initialize = function() {
    var $historyItems, $historyWrapper, containers, historySly, newsWrapper, parallaxWrapper;
    $('#main-menu-toggle, #main-menu-toggle-2').on('click', function() {
      var isActive;
      isActive = $('#main-menu-toggle').hasClass('active');
      if (isActive) {
        $('#main-menu-toggle').removeClass('active');
        $('#main-menu-toggle-2').removeClass('active');
        return $('#main-menu-section').removeClass('active');
      } else {
        $('#main-menu-toggle').addClass('active');
        $('#main-menu-toggle-2').addClass('active');
        return $('#main-menu-section').addClass('active');
      }
    });
    $('.main-menu a, .link-imprint, .link-privacy-policy').on('click', function() {
      $('#main-menu-toggle').removeClass('active');
      $('#main-menu-toggle-2').removeClass('active');
      return $('#main-menu-section').removeClass('active');
    });
    try {
      $('#data-protection-popup button').on('click', function() {
        $('#data-protection-popup').remove();
        return Cookies.set('data-protection', 'accepted', {
          expires: 30
        });
      });
    } catch (error) {

    }
    $(window).on('scroll', function() {
      return handleScroll();
    });
    handleScroll();
    containers = $('section[role="main"]').find('.news');
    if (containers && containers.length > 0) {
      newsWrapper = $('<div>').addClass('news-wrapper');
      $('section[role="main"]').prepend(newsWrapper);
      containers.prependTo(newsWrapper);
    }
    containers = $('section[role="main"]').find('.parallax');
    if (containers && containers.length > 0) {
      parallaxWrapper = $('<div>').addClass('parallax-wrapper');
      $('section[role="main"]').prepend(parallaxWrapper);
      containers.prependTo(parallaxWrapper);
    }
    $historyWrapper = $('.precious-metal-history');
    if ($historyWrapper.length) {
      $historyItems = $historyWrapper.find('ul.history').children('li');
      historySly = new Sly($historyWrapper.find('.frame'), {
        slidee: $historyWrapper.find('.slidee'),
        prev: $historyWrapper.find('.slidee-prev'),
        next: $historyWrapper.find('.slidee-next'),
        horizontal: 1,
        itemNav: 'centered',
        smart: 1,
        activateOn: 'click',
        mouseDragging: 1,
        touchDragging: 1,
        releaseSwing: 1,
        startAt: 6,
        activatePageOn: 'click',
        speed: 300,
        elasticBounds: 1,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1
      });
      historySly.on('active', (function(_this) {
        return function(e, index) {
          $historyItems.removeClass('active');
          return $($historyItems.get(index)).addClass('active');
        };
      })(this));
      $(window).resize((function(_this) {
        return function() {
          return historySly.reload();
        };
      })(this));
      historySly.init();
      return $(window).on('scroll', slideeAttention);
    }
  };

  $(window).on('load', function() {
    return initialize();
  });

}).call(this);
