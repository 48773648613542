isSticky = false

isVisible = (el) ->
    $t            = $(el)
    $w            = $(window)
    viewTop       = $w.scrollTop()
    viewBottom    = viewTop + $w.height()
    _top          = $t.offset().top
    _bottom       = _top + $t.height()
    compareTop    = _bottom
    compareBottom = _top
    
    ((compareBottom <= viewBottom) && (compareTop >= viewTop))

slideeAttention = () ->
  $('.slidee-prev, .slidee-next').each (i, el) =>
    el = $(el)
    if isVisible(el, true)
      el.addClass('flash')
      setTimeout ->
        el.removeClass('flash')
      , 5000
      $(window).off 'scroll', slideeAttention

handleScroll = ->
  scrollTop = $(window).scrollTop()

  # Sticky header
  #shouldSticky = scrollTop >= $(window).height()
  shouldSticky = scrollTop>= 96
  if shouldSticky != isSticky
    if shouldSticky
      $(document.body).addClass('sticky-header')
    else
      $(document.body).removeClass('sticky-header')
    isSticky = shouldSticky

  # Parallax effect
  $('.parallax-wrapper').each((i, parallax) ->
    $pallax = $(parallax)
    parallaxTop = $pallax.offset().top

    shouldFixed = scrollTop >= parallaxTop
    isFixed = $pallax.data('background-attachment-fixed')
    if shouldFixed != isFixed
      if shouldFixed
        $pallax.css('background-attachment', 'fixed')
        $pallax.data('background-attachment-fixed', true)
      else
        $pallax.css('background-attachment', 'scroll')
        $pallax.data('background-attachment-fixed', false)

    # parallaxHeight = $pallax.height()
    # if scrollTop >= parallaxTop + parallaxHeight - 600
    #   parallaxStop = (parallaxTop + parallaxHeight - 600) - scrollTop
    #   console.log('parallax stop: ' + parallaxStop)
    #   $pallax.css('background-position', '0px -' + parallaxStop + 'px')
    # else
    #   $pallax.css('background-position', '0 0')
  )

initialize = ->
  # Menu toggle
  $('#main-menu-toggle, #main-menu-toggle-2').on 'click', () ->
    isActive = $('#main-menu-toggle').hasClass('active')
    if isActive
      $('#main-menu-toggle').removeClass('active')
      $('#main-menu-toggle-2').removeClass('active')
      $('#main-menu-section').removeClass('active')
    else
      $('#main-menu-toggle').addClass('active')
      $('#main-menu-toggle-2').addClass('active')
      $('#main-menu-section').addClass('active')

  # Hide menu on item click
  $('.main-menu a, .link-imprint, .link-privacy-policy').on 'click', () ->
    $('#main-menu-toggle').removeClass('active')
    $('#main-menu-toggle-2').removeClass('active')
    $('#main-menu-section').removeClass('active')

  # Data protection popup
  try
    $('#data-protection-popup button').on 'click', () ->
      $('#data-protection-popup').remove()
      Cookies.set('data-protection', 'accepted', { expires: 30 })
  catch
    # console.log('Unable to check data protection settings')  

  # Scroll event handler
  $(window).on 'scroll', () -> handleScroll()
  handleScroll()

  # News content container
  containers = $('section[role="main"]').find('.news')
  if containers && containers.length > 0
    newsWrapper = $('<div>').addClass('news-wrapper')
    $('section[role="main"]').prepend(newsWrapper)
    containers.prependTo(newsWrapper)

  # Parallax content container
  containers = $('section[role="main"]').find('.parallax')
  if containers && containers.length > 0
    parallaxWrapper = $('<div>').addClass('parallax-wrapper')
    $('section[role="main"]').prepend(parallaxWrapper)
    containers.prependTo(parallaxWrapper)

  # Color selector
  # colorScheme = $('body').data('color-scheme')
  # console.log('[Color scheme]: ' + colorScheme)
  # if $('body').data('color-scheme') == 'colored'
  #   $cssLinks = $('link[media="none"]')
  #   $colorSwitches = $('#color-selector').css('display', 'block').find('a')
  #   $colorSwitches.on 'click', (e) ->
  #     color = $(e.target).attr('class')
  #     # console.log('COLOR: ' + color)
  #     $cssLinks.attr('media', 'none')
  #     $cssLinks.filter('[data-color="' + color + '"]').attr('media', 'screen')
  #     e.preventDefault()
  #     return false

  # Initialize sly for precious metal history
  $historyWrapper = $('.precious-metal-history')
  if $historyWrapper.length
    $historyItems = $historyWrapper.find('ul.history').children('li')
    historySly = new Sly($historyWrapper.find('.frame'), {
      slidee: $historyWrapper.find('.slidee'),
      prev: $historyWrapper.find('.slidee-prev'),
      next: $historyWrapper.find('.slidee-next'),
      horizontal: 1,
      itemNav: 'centered',
      smart: 1,
      activateOn: 'click',
      mouseDragging: 1,
      touchDragging: 1,
      releaseSwing: 1,
      startAt: 6,
      activatePageOn: 'click',
      speed: 300,
      elasticBounds: 1,
      dragHandle: 1,
      dynamicHandle: 1,
      clickBar: 1,
    })

    # Activate remote content item
    historySly.on 'active', (e, index) =>
      $historyItems.removeClass('active')
      $($historyItems.get(index)).addClass('active')

    # Reload on viewport resize
    $(window).resize () => historySly.reload()

    historySly.init()

    # Attention seeker
    $(window).on 'scroll', slideeAttention

$(window).on('load', () -> initialize())
